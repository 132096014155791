import React from "react"
import PropTypes from "prop-types"
import GalleryItem from "./galleryItem"

const Gallery = props => {
  const { theme, images } = props

  return (
    <React.Fragment>
      <main className="main">
        <div className="galleryContainer">
            {images.map(image => {
              const {
                node,
                node: { id },
              } = image

              return <GalleryItem key={id} theme={theme} images={node} />
            })}
        </div>
      </main>

      {/* --- STYLES --- */}
      <style jsx>{`
        .main {
          padding: ${theme.space.xl} ${theme.space.m} ${theme.space.m}
            ${theme.space.m};
        }

        .galleryContainer {
          column-count: 2;
          column-width: 750px;
          max-width: 1550px;
          margin: auto;
        }

        @media (max-width: 1710px) {
          .galleryContainer {
            column-count: 1;
            display: flex;
            flex-direction: column;
            align-items: center;
          }
        }

        @media (max-width: 1550px) {
          .main {
            padding: ${theme.space.s} ${theme.space.xs} ${theme.space.xs}
              ${theme.space.s};
          }
        }
      `}</style>
    </React.Fragment>
  )
}

Gallery.propTypes = {
  theme: PropTypes.object.isRequired,
  galleryPosts: PropTypes.array.isRequired,
}

export default Gallery

/*
.galleryContainer {
                    display: flex;
                    align-items: flex-start;
                    flex-wrap: wrap;
                    justify-content: space-evenly;
                }
*/
