import Img from "gatsby-image"
import React from "react"
import PropTypes from "prop-types"
//import { Link } from "gatsby";
var Fraction = require("fraction.js")

const galleryItem = props => {
  const {
    theme,
    images: {
      publicURL,
      childImageSharp: {
        fluid,
        fields: { exif },
      },
    },
  } = props

  var exposureTime
  if (exif.raw.exif.ExposureTime >= 1) {
    exposureTime = exif.raw.exif.ExposureTime + `s`
  } else {
    var x = new Fraction(exif.raw.exif.ExposureTime)
    exposureTime = "<sup>" + x.n + "</sup>&frasl;<sub>" + x.d + "</sub> s"
  }

  return (
    <React.Fragment>
      <div className="imageBox">
        <a href={publicURL}>
          <div className="imageWrapper">
            <Img fluid={fluid} />
            <div className="exifOverflowWrapper">
              <div className="exifWrapperHover">
                <div>ISO{exif.raw.exif.ISO}</div>
                <div>
                  <i>f</i> / {exif.raw.exif.FNumber}
                </div>
                <div>{exif.raw.exif.FocalLengthIn35mmFormat} mm</div>
                <div dangerouslySetInnerHTML={{ __html: exposureTime }}></div>
              </div>
            </div>
          </div>
        </a>
      </div>

      {/* --- STYLES --- */}
      <style jsx>{`
        .imageBox {
          position: relative;
          width: 100%;
          max-width: 700px;
          min-width: 300px;
          padding: ${theme.space.m};
          margin-top: -${theme.space.m};
          break-inside: avoid;
        }

        .imageBox:first-child {
          margin-top: 0;
        }

        .imageWrapper {
          position: relative;
          box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2),
            0 6px 20px 0 rgba(0, 0, 0, 0.19);
        }

        .imageWrapper :global(.gatsby-image-wrapper) {
          overflow: visible !important;
        }

        .exifOverflowWrapper {
          position: absolute;
          top: 0;
          right 0;
          width: 100%;
          height: 100%;
          background-color: transparent;
          overflow: hidden;
        }

        .exifOverflowWrapper .exifWrapperHover {
          position: absolute;
          display: flex;
          flex-direction: column;
          justify-content: space-around;
          top: 0;
          right: 0;
          width: 15%;
          height: 100%;
          z-index: 1;
          font-size: 1rem;
          font-weight: 400;
          letter-spacing: 0.05rem;
          align-items: center;
          transform: translate(100%);
          transition: transform 0.5s ease-in;
          background-color: ${theme.color.background.white_exif};
        }

        .imageWrapper:hover .exifWrapperHover {
          transform: translate(0%);
          color: ${theme.color.neutral.category_blue};
        }

        @media (max-width: 600px) {
          .imageBox .exifWrapperHover {
            width: 25%;
            font-size: 0.8rem;
          }
        }
        @media (max-width: 1550px) {
          .imageBox {
            width: 100%;
          }
        }
      `}</style>
    </React.Fragment>
  )
}

galleryItem.propTypes = {
  theme: PropTypes.object.isRequired,
  images: PropTypes.object.isRequired,
}

export default galleryItem
