import React from "react"
import { graphql } from "gatsby"
import Layout from "../layouts/layout"
import PropTypes from "prop-types";
import theme from "../theme/theme.yaml"
import SEO from "../components/seo/seo"
import Gallery from "../components/gallery/gallery"


class GalleryPage extends React.Component {
  render() {
    const {
      data: {
        galleryPosts: { edges: galleryPosts = [] },
        site: {
          siteMetadata: { title, description },
        },
        images: { edges: images = [] }
      },
    } = this.props
    

    return (
      <React.Fragment>
        <SEO title={title} description={description} />
        <Layout>
          <div className="galleryContainer">
            <h1>
            <Gallery galleryPosts={galleryPosts} theme={theme} images={images}/>
            </h1>
          </div>
        </Layout>

        <style jsx>{`
          .galleryContainer {
            position: relative;
            background-color: ${theme.color.background.white_opac};
            z-index: 0;
            width: 95vw;
            max-width: ${`calc(${theme.text.maxWidth.desktop} *3)`};         
            margin: ${`calc(${theme.header.height.homepage} + ${theme.space.xl})`} auto ${theme.space.xl};
            color: ${theme.color.neutral.gray.h};
            padding: ${theme.space.xl} ${theme.space.s};
          }

          .galleryContainer :global(h1) {
            padding: ${theme.space.m} ${theme.space.s};
            font-size: 2.5rem;
          }
        `}</style>
      </React.Fragment>
    )
  }
}

export default GalleryPage

GalleryPage.propTypes = {
  data: PropTypes.object.isRequired
};

export const query = graphql`
  query {
    galleryPosts: allMarkdownRemark(
      filter: {
        frontmatter: { status: { eq: "public" } }
        fileAbsolutePath: { regex: "//gallery/[0-9]+.*-/" }
      }
      sort: { fields: frontmatter___date, order: DESC }
    ) {
      edges {
        node {
          id
          excerpt
          fields {
            slug
          }
          fileAbsolutePath
          frontmatter {
            author
            category
            title
            date(formatString: "DD.MM.YYYY")
            status
            featuredImage {
              childImageSharp {
                fluid(maxWidth: 800, maxHeight: 360) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
    site {
      siteMetadata {
        title
        description
      }
    }
    images: allFile(filter: {sourceInstanceName: {eq: "gallery"}, extension: {ne: "md"}}, sort: {fields: relativePath, order: DESC}) {
      edges {
        node {
          id
          relativeDirectory
          relativePath
          publicURL
          name
          base
          sourceInstanceName
          childImageSharp {
            fluid(maxWidth: 1200, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
            fields {
              exif {
                raw {
                  exif {
                    FNumber
                    ISO
                    FocalLength
                    FocalLengthIn35mmFormat
                    LensModel
                    ExposureTime
                  }
                  image {
                    Make
                    Model
                  }
                }
                meta {
                  dateTaken
                }
              }
            }
          }
        }
      }
    }
  }
`
